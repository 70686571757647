<template>
  <div>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
  },
  mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  },
};
</script>

<style>
:root {
  --success-color: #59b97f;
  --warning-color: #f9d573;
  --error-color: #fe7f72;
  --dynad-color-background: #323232;
  --dynad: #d6611f;
}

/* date picker styles start */
.reportrange-text {
  cursor: pointer;
  border: 1px solid #e16e2e !important;
  padding: 6px 0px 0px 0px !important;
  align-items: center;
  height: 30px;
  overflow: hidden;
  background: transparent !important;
}
.fa-calendar:before {
  content: none !important;
}
.caret {
  display: none;
}
.daterangepicker {
  background-color: #323232 !important ;
  border: 1px solid var(--dynad) !important;
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: none;
}
.daterangepicker .drp-buttons {
  border: none !important;
}
.daterangepicker .calendar-table {
  background-color: var(--dynad-color-background) !important;
  border: none !important;
}
.table-condensed {
  color: white !important;
  background-color: var(--dynad-color-background);
}
.daterangepicker.ltr .drp-calendar.left {
  border: none !important;
  padding-right: 0px;
}
.table-condensed > tbody > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th {
  background-color: var(--dynad-color-background);
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--dynad) !important;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: var(--dynad-color-background) !important;
}
.daterangepicker td.in-range {
  background-color: #b352193d !important;
  color: white !important;
}

.daterangepicker td.start-date {
  border-radius: 0px !important;
  background-color: var(--dynad) !important;
}
.daterangepicker td.end-date {
  border-radius: 0px !important;
  background-color: var(--dynad) !important;
}
.daterangepicker .ranges li.active,
.daterangepicker .ranges li.active:hover,
.daterangepicker .ranges li:hover {
  background-color: var(--dynad) !important;
  color: #fff !important;
}
.applyBtn {
  background: var(--dynad) !important;
  color: white !important;
}
.applyBtn:hover {
  background-color: var(--dynad) !important;
  color: white !important;
  background-image: none !important;
}
.cancelBtn,
.cancelBtn:hover {
  background: white !important;
  color: #0070ab !important;
}
.drp-selected {
  font-size: 12pt !important;
  color: white;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
td.today {
  background-color: var(--dynad) !important;
  color: #f3a071 !important;
  font-weight: 700;
}
.vue-daterange-picker {
  width: 100% !important;
  text-align: center;
}
.calendars {
  border: none !important;
  flex-wrap: nowrap !important;
}
.calendars > * {
  border-width: 0 !important;
}
.daterangepicker .monthselect {
  background-color: var(--dynad-color-background) !important;
  color: white;
}
.daterangepicker .monthselect,
.daterangepicker .yearselect {
  background-color: var(--dynad-color-background) !important;
  color: white;
}
/* date picker styles end */

/* barra de rolagem start*/
.scroll-dynad::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.scroll-dynad::-webkit-scrollbar-button {
  height: 0px;
  width: 0px;
  background-color: invisible;
}
.scroll-dynad::-webkit-scrollbar-thumb:horizontal,
.scroll-dynad::-webkit-scrollbar-thumb:vertical {
  background-color: #d6611fa6;
  border-radius: 8px;
}
.scroll-dynad::-webkit-scrollbar-corner {
  border-radius: 5px;
}
.scroll-dynad::-webkit-scrollbar-track-piece {
  background-color: #d6611f2e;
}
/* barra de rolagem end */
</style>
