import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/Errors/NotFoundPage.vue";

// Admin pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Dashboard.vue");
const ConsolidatedReport = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/ConsolidatedReport.vue");
const CustomReport = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/CustomReport.vue");
const Apontamentos = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/Apontamentos.vue");
const Configuracao = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/pages/Apontamento/Configuracao.vue"
  );
const CriarParametro = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/pages/Apontamento/CriarParametro.vue"
  );
const CustomReportForm = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/CustomReportForm.vue");
const HeatMap = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/HeatMap.vue");
const CampaignMonitor = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/CampaignMonitor.vue");
const DeliveryPerformance = () =>
  import(/* webpackChunkName: "dashboard" */ "@/pages/DeliveryPerformance.vue");
const Profile = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Notifications.vue");
const Icons = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const UnderConstruction = () =>
  import(/* webpackChunkName: "common" */ "@/pages/UnderConstruction.vue");
const Typography = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () =>
  import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");
const Forbidden = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Errors/Forbidden.vue");

const routes = [
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "forbidden",
        name: "forbidden",
        component: Forbidden
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "placement",
        name: "placement",
        component: UnderConstruction
      },
      {
        path: "campaign",
        name: "campaign",
        component: UnderConstruction
      },
      {
        path: "creative",
        name: "creative",
        component: UnderConstruction
      },
      {
        path: "native",
        name: "native",
        component: UnderConstruction
      },
      {
        path: "consolidated-report",
        name: "consolidated report",
        component: ConsolidatedReport
      },
      {
        path: "custom-report",
        name: "custom report",
        component: CustomReport
      },
      {
        path: "apontamentos",
        name: "apontamentos",
        component: Apontamentos
      },

      {
        path: "/apontamentos/configuracao",
        name: "configuracao",
        component: Configuracao
      },
      {
        path: "/apontamentos/configuracao/criarParametro",
        name: "Criar Parametro",
        component: CriarParametro
      },

      {
        path: "custom-report-form",
        name: "custom report form",
        component: CustomReportForm
      },
      {
        path: "heatmap",
        name: "heatmap",
        component: HeatMap
      },
      {
        path: "campaign-monitor",
        name: "campaign monitor",
        component: CampaignMonitor
      },
      {
        path: "delivery-performance",
        name: "delivery performance",
        component: DeliveryPerformance
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      }
    ]
  },
  {
    path: "*",
    component: NotFound
  }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
