<template>
  <nav
    class="navbar navbar-expand-lg navbar-absolute"
    :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }"
  >
    <div class="container-fluid">
      <div class="navbar-wrapper">
        <div
          class="navbar-toggle d-inline"
          :class="{ toggled: $sidebar.showSidebar }"
        >
          <button
            type="button"
            class="navbar-toggler"
            aria-label="Navbar toggle button"
            @click="toggleSidebar"
          >
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </button>
        </div>
        <div id="container" style="white-space:nowrap">
          <div id="image" style="display:inline;">
            <a :href=url target="_blank">
                <img :src=logo :alt=title>
            </a>
          </div>
          <div id="texts" style="display:inline; white-space:nowrap; padding-left: 10px;">
            <a :href=url target="_blank" style="text-transform: uppercase; font-weight: 400; color: white;">
              {{title}}
            </a>
          </div>
        </div>
      </div>
      <button
        class="navbar-toggler"
        type="button"
        @click="toggleMenu"
        data-toggle="collapse"
        data-target="#navigation"
        aria-controls="navigation-index"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
        <span class="navbar-toggler-bar navbar-kebab"></span>
      </button>

      <collapse-transition>
        <div class="collapse navbar-collapse show" v-show="showMenu">
          <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
            <base-dropdown v-if="$store.state.notifications.length" tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <div class="dynad-notification d-none d-lg-block d-xl-block"></div>
                <i class="tim-icons icon-bell-55 mt-1"></i>
              </a>
              <li v-for="notification in notifications">
                <a href="/#/campaign-monitor" class="nav-item dropdown-item">{{ notification }}</a>
              </li>
            </base-dropdown>
            <base-dropdown v-else tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item">
              <a slot="title" href="#" class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="true">
                <i class="tim-icons icon-bell-55 mt-1"></i>
              </a>
              <li class="nav-link">
                <a href="#" class="nav-item dropdown-item">Nenhuma Notificação Recente</a>
              </li>
            </base-dropdown>
            <div class="select-search">
              <vSelect
                class="style-chooser"
                @input="onSelect"
                label="text"
                :options="customers"
                :value="$store.state.selectedCustomer"
              />
            </div>
            <base-dropdown
              tag="li"
              :menu-on-right="!$rtl.isRTL"
              title-tag="a"
              class="nav-item"
              menu-classes="dropdown-navbar"
            >
              <a
                slot="title"
                href="#"
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div class="photo">
                  <img src="img/default-avatar.png" />
                </div>
                <b class="caret d-none d-lg-block d-xl-block"></b>
                <p class="d-lg-none">Log out</p>
              </a>
              <li class="nav-link">
                <a
                  :href=url
                  class="nav-item dropdown-item"
                  >Return To AdServer</a
                >
              </li>
              <div class="dropdown-divider"></div>
              <li class="nav-link">
                <a
                  :href=url
                  class="nav-item dropdown-item"
                  >Log out</a
                >
              </li>
            </base-dropdown>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import Modal from "@/components/Modal";
import { mapMutations } from "vuex";
import vSelect from "vue-select";
import querystring from "querystring";

export default {
  components: {
    CollapseTransition,
    Modal,
    vSelect,
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    customers() {
      return this.$store.state.customers;
    },
    notifications() {
      return this.$store.state.notifications;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
      title: "DynAd Analytics",
      url: "https://a.dynad.net/",
      logo: "img/DynAdLogo-White.png"
    };
  },
  watch: {
    customers() {
      const customerId = this.$route.query.c;
      if (this.customers.length > 0) {
        const selectedCustomer = this.customers.find(
          (customer) => customer.value == customerId
        );
        if (selectedCustomer && Object.keys(selectedCustomer).length > 0) {
          this.$store.commit("setSelectedCustomer", selectedCustomer);
        }
      }
    },
  },
  methods: {
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    onSelect(selected) {
      this.$store.commit("setSelectedCustomer", selected);
    },
  },
  mounted() {
    let hostname = window.location.hostname;
    if (hostname == "analytics.tailtarget.com") {
      this.title = "Analytics";
      this.url = "https://showcase.tailtarget.com/";
      this.logo = "img/LogoTT.png"
    }
  }
};
</script>
<style>
.select-search {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
}

.select-search > div {
  min-width: 250px;
}

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #d6611f;
  border: none;
  color: white;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser * {
  color: white !important;
}

.style-chooser .vs-selected {
  word-break: break-all;
}
.style-chooser .vs__selected-options {
  flex-wrap: nowrap;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: rgba(255, 255, 255, 0.7);
}
</style>
