import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import router from "./router/index";
import api from "./plugins/api";
import store from "./store";

import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import "vue-select/dist/vue-select.css";
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(api);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  beforeCreate() { this.$store.commit('initialiseStore');},
  render: h => h(App)
}).$mount("#app");
