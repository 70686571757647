<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/delivery-performance" :name="$t('sidebar.deliveryPerformance')" icon="tim-icons icon-chart-bar-32"/>
        <sidebar-link to="/campaign-monitor" :name="$t('sidebar.campaignMonitor')" icon="tim-icons icon-sound-wave"/>
        <sidebar-link to="/heatmap" :name="$t('sidebar.heatMap')" icon="tim-icons icon-square-pin"/>
        <!--sidebar-link to="/placement" :name="$t('sidebar.placement')" icon="tim-icons icon-components"/>
        <sidebar-link to="/campaign" :name="$t('sidebar.campaign')" icon="tim-icons icon-bullet-list-67"/>
        <sidebar-link to="/creative" :name="$t('sidebar.creative')" icon="tim-icons icon-image-02"/>
        <sidebar-link to="/native" :name="$t('sidebar.native')" icon="tim-icons icon-caps-small"/-->
        <sidebar-link to="/consolidated-report" :name="$t('sidebar.consolidatedReport')" icon="tim-icons icon-paper"/>
        <sidebar-link to="/custom-report" :name="$t('sidebar.customReport')" icon="tim-icons icon-single-copy-04"/>
        <sidebar-link to="/apontamentos" :name="$t('sidebar.apontamentos')" icon="tim-icons icon-bulb-63"/>
      </template>
    </side-bar>
    <div class="dynad-top-bar">
      <top-navbar></top-navbar>
    </div>
    <div class="main-panel">

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    async getMonitorAlertsByCustomer(customerId) {
      let params = {
        customer : customerId
      }
      const response = await this.$api.getMonitorAlertsByCustomer(params);
      return response.data;
    },
    async createNotifications() {
      const customer = this.$store.state.selectedCustomer.value;
      let data = await this.getMonitorAlertsByCustomer(customer);
      let notifications = [];
      if (data) {
        let campaigns_zero_impressions = data["campaigns_zero_impressions"];
        if (campaigns_zero_impressions && campaigns_zero_impressions.length > 0) {
          let campaign_zero_impressions_notification = `${campaigns_zero_impressions.length} Campanhas Ativas com 0 (Zero) Impressões`;
          notifications.push(campaign_zero_impressions_notification);
        }
        let campaigns_high_ctr = data["campaigns_high_ctr"];
        if (campaigns_high_ctr && campaigns_high_ctr.length > 0) {
          let campaign_high_ctr_notification = `${campaigns_high_ctr.length} Campanhas Ativas com Possível CTR Alto`;
          notifications.push(campaign_high_ctr_notification);
        }
        this.$store.commit("setNotifications", notifications);
      } else {
        this.$store.commit("setNotifications", notifications);
      }
    }
  },
  mounted() {
    this.createNotifications();
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.createNotifications();
    }
  }
};
</script>
