import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedCustomer: {},
    customers: [],
    user: "",
    error: false,
    notifications: [],
    formattedPercentagesDeviceClicks: [],
    formattedPercentagesDeviceImpressions: [],
    formattedPercentagesFormatClicks: [],
    formattedPercentagesFormatImpressions: [],
    formattedPercentagesFormatCTR: [],
    formattedPercentagesBackupImpressions: [],
    apontamentos: [],
    apontamentosResolved: [],
    rule: [],
    rules: []
  },
  mutations: {
    setFormattedPercentagesDeviceClicks(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesDeviceClicks",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesDeviceClicks = formattedPercentages;
    },
    setFormattedPercentagesDeviceImpressions(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesDeviceImpressions",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesDeviceImpressions = formattedPercentages;
    },
    setFormattedPercentagesFormatClicks(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesFormatClicks",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesFormatClicks = formattedPercentages;
    },
    setFormattedPercentagesFormatImpressions(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesFormatImpressions",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesFormatImpressions = formattedPercentages;
    },
    setFormattedPercentagesFormatCTR(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesFormatCTR",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesFormatCTR = formattedPercentages;
    },
    setFormattedPercentagesBackupImpressions(state, formattedPercentages) {
      localStorage.setItem(
        "formattedPercentagesBackupImpressions",
        JSON.stringify(formattedPercentages)
      );
      state.formattedPercentagesBackupImpressions = formattedPercentages;
    },
    setNotifications(state, notifications) {
      localStorage.setItem("notifications", JSON.stringify(notifications));
      state.notifications = notifications;
    },
    setSelectedCustomer(state, customer) {
      localStorage.setItem("selectedUser", JSON.stringify(customer));
      state.selectedCustomer = customer;
    },
    setCustomers(state, customers) {
      localStorage.setItem("customers", JSON.stringify(customers));
      state.customers = customers;
    },
    setUser(state, user) {
      localStorage.setItem("user", user);
      state.user = user;
    },
    setApontamentos(state, apontamentos) {
      localStorage.setItem("apontamentos", apontamentos);
      state.apontamentos = apontamentos;
    },
    setApontamentosResolved(state, apontamentosResolved) {
      localStorage.setItem("apontamentosResolved", apontamentosResolved);
      state.apontamentosResolved = apontamentosResolved;
    },
    setRules(state, rules) {
      localStorage.setItem("rules", state.rules);
      state.rules = rules;
    },
    setRule(state, rule) {
      localStorage.setItem("rule", state.rule);
      state.rule = rule;
    },
    setAuthError(state, errorState) {
      state.error = errorState;
    },
    initialiseStore(state) {
      let localStorageSelectedUser = localStorage.getItem("selectedUser");
      if (localStorageSelectedUser) {
        state.selectedCustomer = JSON.parse(localStorageSelectedUser);
      }

      let localStorageCustomers = localStorage.getItem("customers");
      if (localStorageSelectedUser) {
        state.customers = JSON.parse(localStorageCustomers);
      }
    }
  },
  actions: {}
});
