import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import querystring from "querystring";

const cache = setupCache({
  maxAge: 1
});

const baseRequestConfig = {
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://datalake-report.dynad.net"
      : "/api",
  timeout: 300000,
  headers: {
    "Content-Type": "application/json"
  },
  adapter: cache.adapter
};

const baseRequestConfigAuth = {
  baseURL: "https://aws-auth.dynad.net",
  timeout: 300000,
  headers: {
    "Content-Type": "application/json"
  },
  adapter: cache.adapter
};

export const httpNoAuth = axios.create(baseRequestConfig);
export const http = axios.create({
  ...baseRequestConfig,
  headers: {
    ...baseRequestConfig.headers,
    Authorization: getLocalToken()
  }
});
export const httpAuth = axios.create({
  ...baseRequestConfigAuth,
  headers: {
    ...baseRequestConfigAuth.headers,
    Authorization: getLocalToken()
  }
});

const PATHS = {
  auth: "/auth/validate",
  dashboard: "/analytics/new-dashboard",
  report: "/report",
  reportConsolidated: "/analytics/report/consolidated",
  reportCustom: "/analytics/report/custom",
  newCustomReport: "/custom_report",
  newCustomReportByDateRage: "/report/custom",
  heatmap: "/analytics/heatmap",
  monitor: "/analytics/monitor",
  deliveryPerformance: "/analytics/delivery-performance"
};

const errorHandlers = {
  "401": () => {
    let hostname = window.location.hostname;
    if (hostname == "analytics.tailtarget.com") {
      window.location = "https://showcase.tailtarget.com/";
    } else {
      window.location = "https://a.dynad.net";
    }
    return;
  },
  "403": () => {
    window.location = "/forbidden";
    return;
  },
  "404": () => {
    window.location = "/404";
    return;
  },
  "500": message => {
    return {
      error: message
    };
  },
  "503": message => {
    return {
      error: message
    };
  }
};

const responseGeneralHandler = [
  response => response,
  error => {
    if (error.response) {
      const { response } = error;
      return errorHandlers[response.status](response);
    }
    return errorHandlers[500](error.message || "Erro ao contactar o servidor!");
  }
];

http.interceptors.response.use(
  responseGeneralHandler[0],
  responseGeneralHandler[1]
);
httpNoAuth.interceptors.response.use(
  responseGeneralHandler[0],
  responseGeneralHandler[1]
);

function handleDashboardDataResponse(response) {
  const { data } = response;
  if (!data) {
    return {};
  }
  return data;
}

function getToken() {
  const query = window.location.hash;
  const token = querystring.parse(query.split("?")[1]).t;
  if (token) {
    localStorage.setItem("__tk__da", token);
  }
  return `Bearer ${token}`;
}

function getLocalToken() {
  const localToken = localStorage.getItem("__tk__da");
  if (localToken) {
    return `Bearer ${localToken}`;
  } else {
    return getToken();
  }
}

export default {
  install(Vue) {
    Vue.prototype.$api = {
      async getDashboardData(query) {
        const response = await http.get(PATHS.dashboard, {
          params: { ...query }
        });
        if (response.error) return {};
        return handleDashboardDataResponse(response);
      },
      async auth() {
        const response = await httpAuth.get(PATHS.auth);
        const user = response.data["user"];
        const customersList = response.data["customers"];
        if (customersList) {
          const customers = customersList.map(customer => ({
            value: customer.id,
            text: customer.name
          }));
          return { user: user, customers: customers };
        }
      },
      async createNewCustomReport(body) {
        const response = await httpNoAuth.post(PATHS.newCustomReport, body);
        return response;
      },
      async createNewCustomReportByDateRange(body) {
        const response = await httpNoAuth.post(
          PATHS.newCustomReportByDateRage,
          body
        );
        return response;
      },
      async downloadReport(query) {
        const response = await httpNoAuth.get(PATHS.report, {
          params: {
            ...query
          },
          responseType: "blob"
        });
        return response;
      },
      async getReportStatus(query) {
        const response = await httpNoAuth.get(PATHS.reportConsolidated, {
          params: {
            ...query
          }
        });
        return response;
      },
      async getCustomReportById(query) {
        const response = await httpNoAuth.get(PATHS.newCustomReport, {
          params: {
            ...query
          }
        });
        return response;
      },
      async getCustomReports(query) {
        const response = await httpNoAuth.get(PATHS.reportCustom, {
          params: {
            ...query
          }
        });
        return response;
      },
      async getImpressionsAndClicks(query) {
        const response = await httpNoAuth.get(PATHS.heatmap, {
          params: {
            ...query
          }
        });
        return response;
      },
      async getMonitorAlertsByCustomer(query) {
        const response = await http.get(PATHS.monitor, {
          params: {
            ...query
          }
        });
        return response;
      },
      async getDeliveryPerformanceByDomainAndCustomer(query) {
        const response = await http.get(PATHS.deliveryPerformance, {
          params: {
            ...query
          }
        });
        return response;
      },
      async deleteCustomScheduledReportById(id) {
        const response = await httpNoAuth.delete(`${PATHS.reportCustom}/${id}`);
        return response;
      },
      async deleteCustomDateReportById(id) {
        const response = await httpNoAuth.delete(
          `${PATHS.reportCustom}/date/${id}`
        );
        return response;
      }
    };
  }
};
