<template>
  <div class="card">
    <div class="card-header">
      <h5 class="title">Not Found</h5>
      <p class="category">
        Desculpe, a página que você procura não foi encontrada!
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
